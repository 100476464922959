<template> 
<div>
  <v-card v-if="entity != null" elevation="0" style="border-radius:0px;" color="transparent">
    
    <!-- TOOLBAR -->
    <v-toolbar elevation="1" height="50" color="transparent" max-height="50">
      
      <v-icon  :color="entity.category.iconColor" class="pr-1 py-0">
        mdi-{{ entity.category.icon }}
      </v-icon>    
      <b class="pr-2">{{ entity.category.name }}</b>

      <v-spacer/>

      <!-- <v-btn fab elevation="0" v-if="commandeEdit == null" x-small @click="updateDataEntity" 
            title="actualiser les données de cette page"
            class="mr-2">
        <v-icon small class="">mdi-refresh</v-icon>
      </v-btn> -->

      <v-btn  fab  elevation="0"  color="" class="mx-1" x-small title="Afficher la page" 
              :to="'/company/'+ entity._id" v-if="inDialog"
              @click="$root.$emit('closeDialogEntity')">
        <v-icon small color="" class="">mdi-link</v-icon>
      </v-btn>

      <v-btn  fab color="blue lighten-1" elevation="0" class="mx-1 d-md-none" x-small title="Contacter" 
              v-if="$store.state.auth.user._id != entity.userOwner._id" 
              @click="startConversation()">
        <v-icon small color="" class="">mdi-message</v-icon>
      </v-btn>

      <v-btn  fab  elevation="0" color="" class="mx-1" x-small title="Supprimer mon activité" 
              v-if="$store.state.auth.user._id == entity.userOwner._id" 
              :to="'/delete-company/'+ entity._id">
        <v-icon small color="" class="">mdi-delete</v-icon>
      </v-btn>
      <v-btn  fab  elevation="0" color="" class="mx-1" x-small title="Modifier les informations" 
              v-if="$store.state.auth.user._id == entity.userOwner._id" 
              :to="'/edit-company/'+ entity._id"
              @click="$root.$emit('closeDialogEntity')">
        <v-icon small color="" class="">mdi-pencil</v-icon>
      </v-btn>

      <v-btn x-small outlined fab class="ml-1"
             @click="$root.$emit('closeDialogEntity')" v-if="inDialog">
        <v-icon small>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <!-- SCROLLABLE -->
    <v-card-text class="pa-0">
      
      <v-row class="mx-0 my-0">
        <v-col cols="12" sm="12" md="12" class="py-0">
          
          <v-list-item class="px-0">
            <v-list-item-avatar height="46" width="46"
                :class="'mr-3'">
              <v-gravatar height="30" style="border-radius:50%; margin-bottom:-6px;"
                          :default-img="'retro'" :email="entity.name"/>
            </v-list-item-avatar>
            
            <v-list-item-content>

              <v-list-item-title class="headline mb-1" style="line-height: 1.3rem;">
                <b href="javascript:" text 
                  style="text-transform:none;font-size:0.7em!important;text-decoration:none;white-space:normal;display:inline;" 
                      :class="'pa-0'">
                      <v-icon v-if="entity.dataType=='user'"  style="margin-bottom:3px;margin-right:-5px;margin-left:-4px;">mdi-account</v-icon>
                      <v-icon v-if="entity.dataType=='company'" :color="entity.category.iconColor" small 
                              style="margin-bottom:3px;margin-right:-1px;">
                              mdi-{{ entity.category.icon }}
                      </v-icon>
                      {{ entity.name }}
                </b>
              </v-list-item-title>
              
              <v-list-item-subtitle class="mb-1" v-if="entity.category!=null">
                <v-icon :color="entity.ownModel.iconColor" small 
                        style="margin-bottom:3px;margin-right:-1px;">
                        mdi-{{ entity.iconFa }}
                </v-icon>
                {{ entity.ownModel.name }}
              </v-list-item-subtitle>

              <v-list-item-subtitle v-show="entity.address!=null ||  entity.city!=null">
                <v-icon small style="margin-bottom:3px;margin-left:-1px;">mdi-map-marker</v-icon>
                {{ entity.address }} {{ entity.city }}
                <template v-if="$store.state.auth.user.coordinates.length > 0">
                  à {{ distance }} km
                </template>
              </v-list-item-subtitle>

            </v-list-item-content>

            <v-list-item-content class="d-none d-md-inline">
              <div class="text-right">
              <v-btn   fab color="blue lighten-1" class="mr-2 mt-2" title="contacter le propriétaire" 
                      :disabled="!$store.state.auth.isLogin || !$store.state.auth.user.isActive || $store.state.auth.user._id == entity.userOwner._id || !$store.getters['auth/accountReady']"
                      @click="startConversation()">
                <v-icon  color="" class="">mdi-message-outline</v-icon>
              </v-btn>
              </div>
            </v-list-item-content>
            
          </v-list-item>
        
          <v-card outlined class="pa-3 mb-3" style="border: 1px dashed grey;">
              <v-gravatar height="23" style="border-radius:50%; margin-bottom:-6px;" class="mr-1"
                          :default-img="'retro'" :email="entity.userOwner.name"/>
            Propriétaire : 
            <router-link style="text-decoration:none;color:white;" 
                        :to="'/user/'+entity.userOwner._id">
                        <b>{{ entity.userOwner.name }}</b>
            </router-link>
          </v-card>
          
          <template v-if="entity.userOwner._id != $store.state.auth.user._id">
            <v-card outlined class="pa-3 mb-5"
                    v-if="entity.description.length > 0">
              <v-card-text class="px-0 pt-0 pb-0 font-papyrus" v-html="nl2br(entity.description)">
              </v-card-text>
            </v-card>
            <v-card color="transparent" outlined class="pa-3 mb-5" v-else>
              <v-card-text class="px-0 pt-0 pb-0">
                <v-icon small>mdi-cancel</v-icon> Aucune description
              </v-card-text>
            </v-card>
          </template>
          
          <div class="flex orange--text mt-3" v-if="!$store.state.auth.isLogin">
            <v-btn  outlined to="/login" class="orange--text">
              <v-icon small>mdi-login</v-icon> Se connecter
            </v-btn><br>
            Connectez-vous pour interagir avec cette entité.
          </div>

        </v-col>

      </v-row>
    </v-card-text>

  </v-card>

  <div class="mt-5" id="wallet-container" v-if="entity.userOwner._id == $store.state.auth.user._id && !inDialog">
    <Wallet :headerLarge="false"/>
  </div>
</div>
</template>

<style>
  @import "~leaflet/dist/leaflet.css";
  .toolbar-basket .v-toolbar__content{
    padding-left:7px;
  }
  @media (max-width: 600px) {
    .tab-transporters .v-tab{
      min-width: unset;
    }
    .tab-transporters .v-toolbar__content{
      padding: 0px;
    }
    .tab-transporters .v-btn{
      padding: 0 10px !important;
      min-width: unset !important;
    }
  }

  .btn-service{
    height: unset !important;
  }
  .btn-service .v-btn__content{
    display: inline!important;
    padding: 10px !important;
  }
</style>


<script>

import axios from "axios"

// import { i18n } from 'vue-lang-router';
import core from '../../plugins/core.js'
const config = require('../../config/' + process.env.NODE_ENV)

import L from 'leaflet';
import Gravatar from 'vue-gravatar'

import Wallet from '@/components/wallet/Wallet.vue'

export default {
  name: 'home',
  props: ['entityParent', 'inDialog', 'commandeEdit'],
  components: {
    'v-gravatar' : Gravatar, Wallet
  },
  data: () => ({
    entity: null,
    basket: [],
    transporter: 'feet',
    recurrent: false,
    recurrenceDays: 1,
    deliveryMode: 'myself',
    sendTo: null,

  }),
  mounted: async function(){


    this.entity = this.entityParent
    this.$store.dispatch('auth/setFocusWalletUid', { uid : this.entity.walletDeposit.uid })
    
    this.$root.$off('updateCompanyPage')
              .$on('updateCompanyPage', () => { 
      this.updateDataEntity()
    })

    this.init()
  },
  methods: {
    init(){
      this.sendTo = this.entity.canSaleToUser!=false ? this.$store.state.auth.user._id : null 
      if(this.sendTo == null && this.$store.state.auth.user.companies.length > 0)
        this.sendTo = this.$store.state.auth.user.companies[0]._id

        console.log("init !!!", this.entity.canSaleToUser, this.$store.state.auth.user._id, this.sendTo)
    },
    monyToEuro(amount){ return amount * config.monyToEuro },
    nl2br: function(str, is_xhtml){
      var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br ' + '/>' : '<br>'
      let newStr = (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2')
      //let maxLength = 150
      // let p = newStr.length > maxLength ? '...' : ''
      // newStr = newStr.substr(0, maxLength) + p
      return newStr
    },
    baseUrl(){ return core.baseUrl() },
    startConversation(){
      this.$store.dispatch('app/openConv', { userId: this.entity.userOwner._id, companyId: this.entity._id }) 
      this.$root.$emit('closeDialogEntity')
    },
    updateDataEntity(){
      if(this.commandeEdit == null)
      axios.post('/map/search', { id: this.entity._id })
          .then((res) => {
            if(!res.data.error) {
              this.entity = res.data.results.companies.entities[0]
            }
            else console.log("error fetching entity", res.data)
          })

    },
    sendCommande(){
      let params = {
          basket: this.basketIds,
          transporterType: this.transporter,
          recurrent: this.recurrent,
          recurrenceDays: this.recurrenceDays,
          deliveryMode: this.deliveryMode,
          buyerId: this.sendTo,
          sellerCompanyId: this.entity._id,
      }
      if(params.basket.length == 0
      || params.buyerId == null){
        console.log("params error", params)
        return 
      }

      if(this.commandeEdit != null) 
        params.commandeEditId = this.commandeEdit._id

      axios.post('/game/save-commande', params)
        .then((res) => {
          console.log("ok", res.data)
          this.$store.dispatch('auth/refreshUserData')
          this.$root.$emit("closeDialogEntity")
        })
        .catch((e)=>{
          console.log("error", e)
        })
    },
    addToBasket(product){
      let prodBK = JSON.parse(JSON.stringify(product))
      console.log("prodBK", prodBK)
      let stock = prodBK.stock >= 1 ? 1 : prodBK.stock
      prodBK.stock = stock
      prodBK.stockDispo = product.stock
      this.basket.push(prodBK)
    },
    cancelProductBasket(product){
      let i = -1
      this.basket.forEach((prod, key) => {
        if(prod._id == product._id) i = key
      })
      if(i > -1) this.basket.splice(i, 1)
    },
    decrement: function(prodBK){
      let step = prodBK.stockDispo < 1 ? 0.1 : 1
      if(prodBK.stock - step <= 0) return
      prodBK.stock -= step
    },
    increment: function(prodBK){
      let step = prodBK.stockDispo < 1 ? 0.1 : 1
      if(prodBK.stock >= prodBK.stockDispo) return
      prodBK.stock += step
    },
    getDistance(origin, destination){
      return core.getDistance(origin, destination)
    },
    autoSelectTransporter(){
      if(!this.canDeliveryHimself) this.deliveryMode = "delivery"
      this.transporter = this.$store.state.map.transportersKeys[this.deliveryMode][0]
      let distance = this.distance
      if(this.$store.state.map.transportersKeys[this.deliveryMode] != null)
      this.$store.state.map.transportersKeys[this.deliveryMode].forEach((keyT)=>{
        //console.log("transporteur actuel", this.$store.state.map.transporters[this.deliveryMode][this.transporter].name)
        //console.log("distance", distance, "basketWeight", this.basketWeight)
        if(this.$store.state.map.transporters[this.deliveryMode][this.transporter].maxDistance < distance
        || this.$store.state.map.transporters[this.deliveryMode][this.transporter].maxWeight < this.basketWeight){
          let transporter = this.$store.state.map.transporters[this.deliveryMode][keyT] 
          //console.log("recherche nouveau transporteur", transporter.name, transporter.maxDistance, ">", distance)
          if(transporter.maxDistance > distance
          && transporter.maxWeight > this.basketWeight) {
            this.transporter = keyT
            //console.log("transporteur changé", this.transporter)
          }
        }
      })
    }
  },
  computed: {
    marker(){ 
      let color = "orange"
      return L.icon({
        iconUrl: '/marker/marker-'+color+'.png',
        shadowUrl: '/marker/marker-shadow.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41]
      });
    },
    amountTotalBasket(){
      let total = 0
      this.basket.forEach(product => {
        total += product.price * product.stock 
      })
      return total
    },
    distance(){
      return this.getDistance(this.$store.state.auth.user.coordinates, this.entity.coordinates)
    },
    distanceCost(){
      if(this.$store.state.map.transporters[this.deliveryMode][this.transporter] == null) return 0
      let distCost = this.$store.state.map.transporters[this.deliveryMode][this.transporter].priceKm
      return this.distance * distCost
    },
    basketWeight(){
      let totalWeight = 0
      this.basket.forEach((product)=>{
        totalWeight += product.weightByUnity * product.stock
      })
      return totalWeight
    },
    canDeliveryHimself(){
      let can = false
      this.$store.state.map.transportersKeys['myself'].forEach((transKey)=>{
        let transporter = this.$store.state.map.transporters['myself'][transKey]
        if(transporter.maxDistance > this.distance
        && transporter.maxWeight > this.basketWeight)
          can = true
      })
      console.log("canDeliveryHimself", can)
      return can
    },
    basketIds(){
      let ids = []
      this.basket.forEach((prod) => {
        ids.push({
          //productId: prod._id,
          key: prod.key,
          name: prod.name,
          quantity: prod.stock,
          price: prod.price,
          priceUnity: prod.priceUnity,
          weightByUnity: prod.weightByUnity,
          ownModel: prod.ownModel,
          icon: prod.icon,
          iconColor: prod.iconColor,
        })
      })
      return ids
    },
    nbParcelles(){
      return this.entity.surface / this.entity.surfaceParcelle
    }, 
    nbEmployeeNeeded(){
      //console.log("nbEmployeeNeeded",  this.entity.surface, this.entity.surfaceParcelle, this.entity.nbEmployeesByParcelle)
      //-1 pour le patron qui compte comme un employé
      return (this.entity.surface / this.entity.surfaceParcelle * this.entity.nbEmployeesByParcelle) - this.entity.employees.length - 1
    },
    sendToEntities(){
      let all = []
      let me = [{ _id: this.$store.state.auth.user._id, name: "Moi - " + this.$store.state.auth.user.name }]
      if(this.entity.canSaleToUser) all = me
      
      if(this.entity.canSaleToCompany)
        all = all.concat(this.$store.state.auth.user.companies)
        
      return all
    },
    commandeEntityBuyer(){
      console.log('this.commandeEdit', this.commandeEdit)
      if(this.commandeEdit == null) return null
      if(this.commandeEdit.buyerCompany != null) return this.commandeEdit.buyerCompany
      else return this.commandeEdit.buyerUser
    },

  },
  
  watch: {
      'entityParent': { immediate: true, 
          async handler () {
            //console.log("alo", this.entityParent, this.entityParent == null)
            if(this.entityParent == null) return
            this.entity = this.entityParent
            this.basket = []
            this.sendTo = null
            this.init()
          }
      },
      

      // 'basketWeight': { immediate: true, 
      //     async handler () {
      //       this.autoSelectTransporter()
      //     }
      // },
      // 'deliveryMode': { immediate: true, 
      //     async handler () {
      //       this.autoSelectTransporter()
      //     }
      // },
      'commandeEdit': { immediate: true, 
          async handler () {
            if(this.commandeEdit == null) return
            //let newBasket = []
            this.basket         = []
            this.commandeEdit.basket.forEach((productB)=>{
              this.entity.productsOut.forEach((product)=>{
                let prodBK = JSON.parse(JSON.stringify(product))
                console.log("prodBK", prodBK)
                if(prodBK.key == productB.key){
                  //prodBK.stock = productB.quantity
                  //let stock = prodBK.stock >= 1 ? productB.quantity : prodBK.stock
                  prodBK.stock = productB.quantity
                  prodBK.stockDispo = product.stock
                  this.basket.push(prodBK)
                }
              })
            })
            //this.basket         = this.commandeEdit.basket
            this.deliveryMode   = this.commandeEdit.deliveryMode
            this.transporter    = this.commandeEdit.transporter
            this.recurrent      = this.commandeEdit.recurrent
            this.recurrenceDays = this.commandeEdit.recurrenceDays
            this.sendTo         = this.commandeEntityBuyer._id
          }
      },
  
      
  }, 
}
</script>
