<template>
<v-col style="padding-bottom:110px;">
  <v-row>
    <v-col cols="12" sm="11" md="10" lg="7" class="px-0 mt-sm-5 mx-auto">
      <CompanyPage v-if="entity != null" 
                  :entityParent="entity" 
                  :inDialog="false">
      </CompanyPage>
    </v-col>
  </v-row>
</v-col>
</template>


<script>
import CompanyPage from '@/components/entity/CompanyPage.vue'
import axios from "axios"

export default {
  name: 'home',
  props: [],
  components: {
    CompanyPage
  },
  data: () => ({
    showDialog: false,
    entity: null,
    commandeEdit: null
  }),
  mounted: async function(){
    
  },
  methods: {
    
  },
  watch: {
      '$store.state.auth.isLogin': { immediate: true, 
          async handler () {
            if(this.$store.state.auth.isLogin){
              this.entity = this.$store.state.auth.user.companies.filter(company => company._id == this.$route.params.companyId)[0]
              if(this.entity != null)
                this.$store.dispatch('auth/setFocusWalletUid', { uid : this.entity.walletDeposit.uid })
            }

            //console.log("$store.state.auth.isLogin", this.entity)
          }
      },
      '$route.params.companyId': { immediate: true, 
          async handler () {
            this.entity = null
            if(this.$store.state.auth.isLogin){
              this.entity = this.$store.state.auth.user.companies.filter(company => company._id == this.$route.params.companyId)[0]

              //console.log("$route.params.companyId", this.$route.params.companyId)
              if(this.entity != null){
                this.$store.dispatch('auth/setFocusWalletUid', { uid : this.entity.walletDeposit.uid })
              }
              else{
                axios.post('/map/search', { id: this.$route.params.companyId })
                      .then((res) => {
                        if(!res.data.error) {
                          this.entity = res.data.results.companies.entities[0]
                        }
                        else console.log("error fetching entity", res.data)
                      })
              }
              //console.log("$store.state.auth.isLogin", this.entity.walletDeposit)
            }
          }
      },
  }
  
}
</script>
